<template>
  <div class="header">
    <div class="nav-wrapper">
      <span class="logo"><a href="/"><img src="../../assets/logo.png" alt="" /><i
            class="logo-title">{{ title }}</i></a></span>
    </div>
    <div class="link" :class="{ 'is-search': isSearch }">
      <div class="search-form">
        <el-autocomplete size="small" round ref="search" class="search inline-input" v-model="keywords" value-key="title"
          :fetch-suggestions="globalSearch" :trigger-on-focus="false" popper-class="home-downlist-suggestion"
          prefix-icon="el-icon-search" autocomplete="off" placement="bottom-end" @select="selectThis"
          @blur="toggleSearch(false)">
        </el-autocomplete>
        <span class="search-icon el-icon-search" @click="toggleSearch(true)"></span>
      </div>
      <!-- <div class="message">
        <span class="message-list"><i class="el-icon-view"></i></span>
      </div> -->
      <div class="user">
        <UserInfoDropDown theme="light"></UserInfoDropDown>
      </div>
    </div>
  </div>
</template>

<script>
import { title } from "../../setting";
import { setOptions } from "../../utils";
export default {
  name: "HeaderPure",
  data() {
    return {
      keywords: "",
      navList: setOptions("category_list"),
      isSearch: false,
      title: "Resister"
    };
  },
  components: {
    UserInfoDropDown: () => import("../user/UserInfoDropDown.vue")
  },
  methods: {
    globalSearch(searchStr, cb) {
      this.$get("/globalSearch", {
        keywords: searchStr,
      }).then((res) => {
        if (res.code != 200 || (res.data && res.data.length == 0)) {
          this.$message.info("未能为您匹配到合适的资源")
          return cb([]);
        }
        cb(res.data);
      });
    },
    selectThis(item) {
      if (item.id && item.category) {
        this.$router.replace({
          path: "/redirect/resources/detail",
          query: {
            id: item.id,
          },
        });
      }
    },
    toggleSearch(status) {
      this.isSearch = status;
      if (status) this.$refs.search.focus();
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  justify-content: space-between;
  padding: 0 40px;
  align-items: center;
  background-color: #fff;

  // padding-bottom: 20px;
  .nav-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 60px;
  }

  .logo {
    display: inline-block;
    height: 32px;
    line-height: 32px;
    padding-right: 20px;
    font-size: 0;

    .logo-title {
      font-family: PingFangSC-Regular, Microsoft Yahei, sans-serif;
    }

    a {
      color: #333;
      font-size: 18px;
    }

    img {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      vertical-align: middle;
    }

    i {
      display: inline-block;
      font-style: normal;
      vertical-align: middle;
      margin-left: 20px;
    }
  }

  .link {
    // min-width: 320px;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    /deep/.el-input__inner {
      background-color: transparent;
      height: 32px;
      line-height: 30px;
      color: #333;
      border-radius: 16px;
    }

    .search-form {
      margin-right: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      .search {
        width: 0;
        overflow: hidden;
        transition: 0.3s;
      }

      .search-icon {
        width: 32px;
        height: 32px;
        line-height: 32px;
        text-align: center;
        display: inline-block;
        position: absolute;
        cursor: pointer;
        font-size: 12px;
        color: #333;
        overflow: hidden;
      }
    }


    .message {
      overflow: hidden;
      width: 18px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 20px;
      color: #c0c4cc;
    }
  }

  .user {
    cursor: pointer;

    img {
      width: 32px;
      height: 32px;
    }
  }
}

.avatar {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px 0;
  min-width: 100px;

  img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }

  span {
    font-size: 14px;
    vertical-align: middle;
    white-space: nowrap;
    margin-left: 10px;
  }
}

.is-search {
  .search {
    width: 210px !important;
  }

  .search-icon {
    width: 0 !important;
  }

  .nav-list {
    width: 0 !important;
  }

  .message {
    width: 0 !important;
    margin-right: 0 !important;
  }
}

.auth-btn {
  color: #333;
  font-size: 12px;
  margin-left: 10px;

  &:hover {
    color: #ffffff;
  }
}
</style>

<style lang="scss">
.home-downlist-suggestion {
  width: 500px !important;
}
</style>
